import styled from "styled-components";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Content,
  Card,
  TitleText,
  OutlinedButton,
  OutlinedBox,
} from "../styles";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, storage } from "../firebase/firebase";
import Loader from "./Loader";
import { useMediaQuery } from "@mui/material";
import toast from "react-hot-toast";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { userContext } from "../context/userContext";
import DepositHistory from "../usertables/DepositHistory";
import ChangeAddressModal from "../modals/ChangeAddressModal";

const Deposit = () => {
  const [active, setActive] = useState("Bitcoin");
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 900px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(user !== null ? false : true);
  const [coin, setCoin] = useState("BTC");
  const [amount, setAmount] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const imageRef = useRef();
  const [imageName, setImageName] = useState("");
  const [imageLink, setImageLink] = useState("");
  const { accounts, currentPrices, userData, addresses } =
    useContext(userContext);
  const [liveAccounts, setLiveAccounts] = useState([]);
  const [changeAddress, setChangeAddress] = useState(false);
  const [changeType, setChangeType] = useState("");
  const [changeCurrent, setChangeCurrent] = useState("");

  // open, type, current

  useEffect(() => {
    if (accounts) {
      const { live } = accounts;
      if (live) {
        const { Stock, Crypto, Fiat } = live;
        const StockAccount = Object.values(Stock);
        const CryptoAccount = Object.values(Crypto);
        setLiveAccounts([...CryptoAccount, ...StockAccount, Fiat]);
      }
    }
  }, [accounts]);

  useEffect(() => {
    if (!loading && user) {
      setLoader(false);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate]);

  function copyAddress(address) {
    navigator.clipboard.writeText(address);
    toast.success("Copied successfully!");
  }

  function handleChange(type, current) {
    setChangeCurrent(current);
    setChangeType(type);
    setChangeAddress(true);
  }

  async function submitDeposit(url) {
    // console.log("yeahh");
    setIsSubmitting(false);

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);
    // console.log(randomOne, randomTwo);

    const str =
      user.uid.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "deposits", str), {
      ref: str,
      user: user.uid,
      status: "pending",
      date: serverTimestamp(),
      amount: Number(amount),
      type: coin,
      proof: url,
    })
      .then(() => {
        toast.success("Deposit request submitted");
        reset();
        setIsSubmitting(false);
      })
      .catch((error) => {
        // console.log(error.message);
        toast.error("There was a problem. Please try again later");
      });
  }

  async function handleDeposit() {
    setIsSubmitting(true);
    if (imageLink) {
      // console.log(imageLink);
      const storageRef = ref(storage, imageLink.name + new Date());
      const uploadTask = uploadBytesResumable(storageRef, imageLink);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            submitDeposit(downloadURL);
          });
        }
      );
    }
    // console.log("coin", coin + "\n", "amount", amount);
  }

  useEffect(() => {
    if (!loading && user) {
      setLoader(false);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate]);

  function handleImageChange() {
    imageRef.current.click();
    setIsUploading(true);

    setTimeout(() => {
      setIsUploading(false);
    }, 5000);
  }

  useEffect(() => {
    if (imageLink) {
      setIsUploading(false);
    }
  }, [imageLink]);

  function handleImageURL(e) {
    const file = e.target.files[0];

    if (file) {
      setImageLink(file);
      setImageName(file.name);
    }
  }

  function handleAmountChange(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount("");
    }
  }

  const amountRef = useRef();

  function reset() {
    setImageName("");
    setImageLink("");
    setAmount("");

    if (amountRef) {
      amountRef.current.value = "";
    }
  }

  async function addToDB() {
    await setDoc(doc(db, "admin", "addresses"), {
      Bitcoin: {
        address: "bc1q6keukh6l4jrnlc2s4ysulkpj3e7jv02w5wtttn",
        name: "Bitcoin",
        symbol: "BTC",
      },
      Ethereum: {
        address: "0xFb645e50a3d737c175d32806A4890DD406f400C3",
        name: "Ethereum",
        symbol: "ETH",
      },
      USDT: {
        address: "0xFb645e50a3d737c175d32806A4890DD406f400C3",
        name: "Tether(ERC-20)",
        symbol: "USDT",
      },
      Dogecoin: {
        address: "DE4TRS17d4MnMUYw5xiauu34K6gcrFDNqR",
        name: "Dogecoin",
        symbol: "DOGE",
      },
    }).then(() => {
      console.log("done");
    });
  }

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active="Deposit"
            open={[isOpen, setIsOpen]}
          />
          <Main>
            {changeAddress && (
              <ChangeAddressModal
                open={{ changeAddress, setChangeAddress }}
                current={changeCurrent}
                type={changeType}
              />
            )}
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            <Content className="content">
              <TitleText>Deposit</TitleText>

              <ContentWrapper>
                <Card
                  style={{ boxSizing: "border-box", height: "fit-content" }}
                >
                  <SubText className="top">Choose Method</SubText>
                  {/* <button onClick={addToDB}>add db</button> */}
                  <div
                    className="contentBody"
                    style={{
                      display: "grid",
                      gap: "0.7rem",
                      height: "max-content",
                    }}
                  >
                    {addresses?.map((method) => (
                      <div
                        onClick={() => {
                          setActive(method?.name);
                        }}
                        className="methodBox"
                        key={method?.name}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "0.5rem",
                            alignItems: "center",
                            fontWeight: "600",
                            fontSize: "16px",
                          }}
                        >
                          <img
                            src={`/asseticons/${method?.symbol}.svg`}
                            alt="logo"
                          />
                          <p>{method?.name}</p>
                        </div>
                        {active === method?.name && (
                          <div
                            className="activeBox"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              // flexWrap: "wrap",
                              gap: "0.8rem",
                              // backgroundColor: "red",
                              // width: "100%",
                              // boxSizing: "border-box",
                            }}
                          >
                            <div
                              style={{
                                display: "grid",
                                gap: "0.8rem",
                              }}
                            >
                              <p className="textBold">Address</p>

                              {/* <input
                                style={{
                                  fontSize: "16px",
                                  border: "1px solid #c9c9c9",
                                  padding: "0.5rem",
                                  borderRadius: "4px",
                                  color: "white",
                                  outline: "none",
                                  backgroundColor: "transparent",
                                }}
                                disabled
                                defaultValue={method.address}
                              /> */}

                              <OutlinedBox
                                onClick={() => copyAddress(method?.address)}
                                className="scrollbar-hidden"
                                style={{
                                  boxSizing: "border-box",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  textOverflow: "ellipsis",
                                  overflow: "auto",
                                }}
                              >
                                <span
                                  className="infot"
                                  style={{
                                    width: "280px",
                                  }}
                                >
                                  {method?.address}
                                </span>
                              </OutlinedBox>

                              <OutlinedButton
                                onClick={() => copyAddress(method?.address)}
                                className="blue btn bgBlue"
                              >
                                Tap to copy address
                              </OutlinedButton>

                              {/* {method?.symbol !== "USDT" && (
                                <OutlinedButton
                                  disabled
                                  className="blue btn bgBlue"
                                >
                                  Or scan QR to make payment
                                </OutlinedButton>
                              )} */}

                              {/* {method?.symbol === "BTC" && (
                                <img
                                  style={{
                                    justifySelf: "center",
                                    maxWidth: "200px",
                                  }}
                                  className="my-8"
                                  src="qrcodes/BTC.jpg"
                                  alt="qr"
                                />
                              )} */}

                              {/* {method?.symbol === "ETH" && (
                                <img
                                  style={{
                                    justifySelf: "center",
                                    maxWidth: "200px",
                                  }}
                                  className="my-8"
                                  src="qrcodes/ETH.jpg"
                                  alt="qr"
                                />
                              )} */}
                              {/* 
                              {method?.symbol === "USDT" && (
                                <img
                                  style={{
                                    justifySelf: "center",
                                    maxWidth: "200px",
                                  }}
                                  className="my-8"
                                  src="qrcodes/USDT.jpg"
                                  alt="qr"
                                />
                              )} */}

                              {userData.admin && (
                                <OutlinedButton
                                  onClick={() =>
                                    handleChange(method?.name, method?.address)
                                  }
                                  className="blue btn bgBlue"
                                >
                                  Tap to change address
                                </OutlinedButton>
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                placeContent: "center",
                              }}
                            ></div>
                            {/* <br /> */}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </Card>
                <Card>
                  <SubText className="top">Submit Payment</SubText>
                  <SubmitContent>
                    <ContentContainer>
                      <BodyText>
                        To deposit, choose the payment method panel and make the
                        payment to the displayed address. After payment has been
                        made, come back to fill this form.
                      </BodyText>
                      <div className="body">
                        <div
                          className="selectWrap"
                          style={{
                            boxSizing: "border-box",
                          }}
                        >
                          <label htmlFor="asset">Asset</label>
                          <div
                            className="selectBox"
                            style={{
                              boxSizing: "border-box",
                              width: "100%",
                            }}
                          >
                            <div className="selectTop">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  position: "relative",
                                  width: "100%",
                                  gap: "0.5rem",
                                  boxSizing: "border-box",
                                  padding: "1rem",
                                }}
                              >
                                <img
                                  src={`/asseticons/${coin}.svg`}
                                  alt="icon"
                                  width={24}
                                  height={24}
                                />
                                <select
                                  name="asset"
                                  style={{
                                    padding: "0",
                                    width: "100%",
                                    boxSizing: "border-box",
                                    display: "flex",
                                  }}
                                  onChange={(e) => setCoin(e.target.value)}
                                >
                                  <option>BTC</option>
                                  <option>ETH</option>
                                  <option>BCH</option>
                                  <option>MATIC</option>
                                  <option>USDT</option>
                                  <option>CRO</option>
                                  <option>LTC</option>
                                  <option>SHIB</option>
                                  {/* <option>USDT</option> */}
                                </select>
                              </div>
                              <img
                                src="/extraicons/arrowdown.svg"
                                alt="select"
                                className="dropDownIcon"
                              />
                              {/* </span> */}
                            </div>
                          </div>
                        </div>

                        <div className="amWrap">
                          <label htmlFor="amount">Amount</label>
                          <div className="amBx">
                            <div className="amTop">
                              <input
                                placeholder="1000"
                                name="amount"
                                type="text"
                                onChange={handleAmountChange}
                                ref={amountRef}
                              />
                              <span className="coinSelector">
                                <img
                                  src={`/asseticons/${coin}.svg`}
                                  alt="coin"
                                />
                                <p>{coin}</p>
                              </span>
                            </div>

                            <div className="captions">
                              <p className="balance">
                                {amount && (
                                  <>
                                    Balance ~ {""}
                                    {liveAccounts.map(
                                      (acc) =>
                                        acc.asset === coin && (
                                          <span
                                            key={acc.asset}
                                            className={
                                              acc.value > 0.05 ? "green" : "red"
                                            }
                                          >
                                            {acc.value > 0.05
                                              ? Number(acc.value).toFixed(3)
                                              : Number(acc.value).toFixed(
                                                  0
                                                )}{" "}
                                            {coin}
                                          </span>
                                        )
                                    )}
                                  </>
                                )}
                              </p>
                              {amount && (
                                <p className="extra">
                                  Total in USD
                                  <span className="green">
                                    {" "}
                                    ~$
                                    {Math.floor(currentPrices[coin] * amount)}
                                  </span>
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* <InputText>
                          <label htmlFor="amount">Amount</label>
                          <input
                            style={{ color: "white" }}
                            type="text"
                            placeholder="1000"
                            onChange={(e) => setAmount(e.target.value)}
                            value={amount}
                          />
                        </InputText> */}

                        {/* <InputNumber>
                          <label htmlFor="">Amount</label>
                          <input type="text" placeholder="Amount" />
                        </InputNumber> */}

                        <input
                          onChange={handleImageURL}
                          type="file"
                          style={{
                            opacity: "0",
                            position: "absolute",
                            pointerEvents: "none",
                          }}
                          ref={imageRef}
                        />
                        <div className="proofContainer">
                          <label>Payment Proof</label>
                          <div className="chooseFileBox">
                            <button onClick={handleImageChange}>
                              {isUploading ? (
                                <img
                                  src="/svg-loaders/tail-spin.svg"
                                  alt="loading"
                                  width={24}
                                  height={24}
                                />
                              ) : (
                                <p>Choose file</p>
                              )}
                            </button>

                            <span>
                              <p>
                                {imageName ? imageName : "No file selected"}
                              </p>
                            </span>
                          </div>
                        </div>

                        <button
                          disabled={
                            !amount || !coin || !imageLink || isSubmitting
                          }
                          type="submit"
                          className={
                            !amount || !coin || !imageLink
                              ? "button disabled"
                              : "button submit"
                          }
                          onClick={handleDeposit}
                        >
                          {isSubmitting ? (
                            <img
                              src="/svg-loaders/tail-spin.svg"
                              alt="loading"
                              // width={16}
                              style={{
                                padding: "10px",
                                width: "20px",
                                height: "auto",
                              }}
                              // height={24}
                            />
                          ) : (
                            <p
                              style={{
                                maxWidth: "max-content",
                                placeSelf: "center",
                              }}
                            >
                              Deposit
                            </p>
                          )}
                        </button>
                      </div>
                    </ContentContainer>
                  </SubmitContent>
                </Card>
              </ContentWrapper>

              <DepositHistory user={user} />
              <br />
              <br />
            </Content>
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #0e121b;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      /* padding: 1rem; */
      background-color: #1f273a;
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      .dropDownIcon {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .sel {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }

  .btn {
    border: 1px solid #1f273a;
    color: white;
  }

  .btn:hover {
    background-color: #1f273a;
    color: #119ffa;
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .proofContainer {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .chooseFileBox {
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      display: grid;
      grid-template-columns: 124px auto;
      align-items: center;

      button {
        background: #1f273a;
        border-radius: 4px;
        border: none;
        margin: 2px;
        cursor: pointer;
        height: 36px;

        p {
          font-weight: 600;
          font-size: 13px;
          line-height: 16px;
          padding: 10px;
          color: #ffffff;
        }
      }

      span {
        display: flex;
        /* text-align: center; */
        min-width: 100%;
        /* place-content: center; */
        margin-left: 10px;
        font-weight: 600;
        font-size: 15px;
        line-height: 16px;
        color: #a3a3a3;
      }
    }
  }

  .activeBox {
    height: 100%;
  }

  .button {
    margin-top: 25px;
  }
`;

const Main = styled.div`
  width: 100%;
  height: 100vh;
  overflow-x: auto;
  box-sizing: border-box;
  margin-bottom: 20px;
  /* padding-bottom: 60px; */
`;

const SubText = styled.p`
  font-weight: 600;
  color: #a3a3a3;
`;

const BodyText = styled.p`
  color: #c9c9c9;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 360px auto;
  grid-template-rows: auto auto;
  box-sizing: border-box;
  gap: 1rem;
  width: 100%;
  height: max-content;
  margin-top: 50px;
  padding-bottom: 60px;

  @media screen and (max-width: 1500px) {
    display: grid;
    grid-template-columns: 360px auto;
  }

  @media screen and (max-width: 800px) {
    display: grid;
    grid-template-columns: auto;
  }

  .body {
    display: grid;
    gap: 1.7rem;
  }

  .contentBody {
    /* /* max-width: 360px; */
    /* width: 100%; */
    display: flex;
    flex-direction: column;
    place-items: center;
    /* gap: 0.5rem; */
    padding: 0.5rem;
    box-sizing: border-box;
  }

  .methodBox {
    background-color: #161c2a;
    padding: 0.84rem;
    border-radius: 0.5rem;
    color: white;
    display: grid;
    gap: 1rem;
    text-align: left;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }
`;
const ContentContainer = styled.div`
  max-width: 360px;
  text-align: left;
  display: grid;
  gap: 1rem;
  padding: 1rem;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const SubmitContent = styled.div`
  border-radius: 0.5rem;
  padding-top: 32px;
  // background-color: #161c2a;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  place-content: center;
`;

export default Deposit;
