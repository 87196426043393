import styled from "styled-components";
import { useContext, useState } from "react";
import {
  ActionButtons,
  Card,
  OutlinedBox,
  Switches,
  TitleText,
} from "../styles";
// import { userContext } from "../context/userContext";
// import axios from "axios";
import Crypto from "./trades/Crypto";
import Stocks from "./trades/Stocks";
import Forex from "./trades/Forex";
import { userContext } from "../context/userContext";

const NewTrade = () => {
  // const panelOptions = ["Trade", "Convert"];
  // const [activePanel, setActivePanel] = useState("Trade");
  const tradeTypes = ["Crypto", "Forex", "Stocks"];
  // const tradeTypes = ["Crypto", "Stocks"];
  const [tradeType, setTradeType] = useState("Crypto");
  const [action, setAction] = useState("buy");
  const [didReset, setDidReset] = useState("");
  // const actions = ["Buy", "Sell"];

  const { currentAccount, dispatch } = useContext(userContext);

  function switchAccount() {
    dispatch({
      type: "switchAccount",
      payload: currentAccount === "live" ? "practice" : "live",
    });
    setDidReset("true");
  }

  const stockassets = [
    { name: "Facebook", symbol: "FB" },
    { name: "Apple", symbol: "AAPL", type: "stock" },
    { name: "PayPal", symbol: "PYPL", type: "stock" },
    { name: "Nvidia", symbol: "NVDA" },
    { name: "Amazon", symbol: "AMZN" },
    { name: "Netflix", symbol: "NFLX" },
    { name: "Tesla", symbol: "TSLA" },
    { name: "Microsoft", symbol: "MSFT" },
    { name: "Google", symbol: "GOOGL" },
  ];

  const cryptoassets = [
    { name: "Bitcoin", symbol: "BTCUSD" },
    { name: "Ethereum", symbol: "ETHUSD" },
    { name: "Solana", symbol: "SOLUSD" },
    { name: "Bitcoin Cash", symbol: "BCHUSD" },
    { name: "Litecoin", symbol: "LTCUSD" },
    { name: "Dogecoin", symbol: "DOGEUSD" },
    { name: "Tether", symbol: "USDTUSD" },
    { name: "Matic", symbol: "MATICUSD" },
    { name: "Avalanche", symbol: "AVAXUSD" },
  ];

  const handleTypeChange = (e) => {
    const type = e.target.value;
    switch (type) {
      case "Crypto":
        setTradeType("Crypto");
        break;
      case "Forex":
        setTradeType("Forex");
        break;
      case "Stocks":
        setTradeType("Stocks");
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <Card>
        <TitleText className="top">Trade</TitleText>
        <ContentWrapper>
          {/* <Switches style={{ width: "100%" }}>
            <button className="active">Trade</button>
            <button>Convert</button>
          </Switches> */}

          {/* Type -- select*/}
          {/* action --- select*/}
          {/* Pair -- select */}
          {/* amount -- amount */}

          <ActionButtons>
            <button
              value="buy"
              className={action === "buy" ? "action buy" : "action"}
              onClick={(e) => {
                setAction(e.target.value);
              }}
            >
              BUY
            </button>
            <button
              value="sell"
              className={action === "sell" ? "action sell" : "action"}
              onClick={(e) => {
                setAction(e.target.value);
              }}
            >
              SELL
            </button>
          </ActionButtons>

          {/* <OutlinedBox style={{ display: "flex" }}>
            <p className="infot">
              Your current account is {currentAccount}.
              <span
                className="clickable"
                style={{ display: "inline", marginLeft: "0.3rem" }}
                onClick={switchAccount}
              >
                Switch
              </span>
            </p>
          </OutlinedBox> */}

          <div className="selectWrap">
            <label htmlFor="type">Type</label>
            <div className="selectBox">
              <div className="selectTop">
                {/* <span> */}
                <select name="type" onChange={handleTypeChange}>
                  {tradeTypes.map((type) => (
                    <option key={type}>{type}</option>
                  ))}
                </select>
                <img src="/extraicons/arrowdown.svg" alt="select" />
                {/* </span> */}
              </div>
            </div>
          </div>
          {tradeType === "Crypto" && (
            <Crypto action={action} assets={cryptoassets} />
          )}
          {tradeType === "Forex" && (
            <Forex action={action} didReset={didReset} />
          )}
          {tradeType === "Stocks" && (
            <Stocks action={action} assets={stockassets} />
          )}
        </ContentWrapper>
      </Card>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 0.5rem;
  width: 100%;
`;

const ContentWrapper = styled.div`
  padding: 1rem;
  display: grid;
  gap: 0.8rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      background-color: #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }
`;

export default NewTrade;
